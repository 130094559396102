export const getSettingsPropsByStructure = (
  settings: PageContent.ICommonSettings | undefined,
  structure: string
): PageContent.ICommonSettingsBlockProperties | undefined => {
  if (!settings || !structure) return undefined;

  return settings.blocks.find((item) => item.structure === structure)?.properties;
};

export const getArticleTag = (tags: UmbracoTypes.ITagItem[]): UmbracoTypes.ITagItem | null =>
  tags
    .map(({ articleCategory, id, name }) => ({
      articleCategory,
      id,
      name,
    }))
    .filter((item) => item.articleCategory)[0];

export const parseBoolean = (params?: UmbracoTypes.TToggle): boolean =>
  params && typeof params === 'string' ? params === '1' : false;

export default {
  parseBoolean,
  getArticleTag,
};
